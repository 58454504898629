import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const InstaMiniature = ({ instaNode }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            instagramUrl
          }
        }
      }
    `
  )

  return (
    <div className="footer-insta-box">
      <a
        className="footer-insta-link"
        href={data.site.siteMetadata.instagramUrl + "p/" + instaNode.id}
        target="_blank"
      >
        <Img
          fluid={instaNode.localFile.childImageSharp.fluid}
          alt={instaNode.caption}
          className="img-responsive"
        />
      </a>
    </div>
  )
}

export default InstaMiniature
