import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import InstaMiniature from "./instaminiature"

const Footer = ({}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            instagramUrl
          }
        }
        allInstaNode(sort: { fields: timestamp, order: DESC }) {
          nodes {
            id
            username
            type
            caption
            likes
            localFile {
              childImageSharp {
                fluid(maxWidth: 226) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <footer className="site-footer">
      <div className="footer-instagram">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <h6 className="footer-instagram-title">
                <a href={data.site.siteMetadata.instagramUrl} target="_blank">
                  Recent adventures from Instagram
                </a>
              </h6>
            </div>
          </div>
          {data.allInstaNode && (
            <div className="row">
              <div className="col-xs-12 col-md-6 no-padd">
                <div className="container-fluid">
                  <div className="row">
                    {data.allInstaNode.nodes
                      .slice(0, 4)
                      .map((instaNode, index) => (
                        <div
                          key={index}
                          className={`col-xs-6 col-sm-3 no-padd insta-image-container ${
                            index > 1 ? "marg-xs-t10" : ""
                          }`}
                        >
                          <InstaMiniature instaNode={instaNode} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 no-padd marg-sm-t10">
                <div className="container-fluid">
                  <div className="row">
                    {data.allInstaNode.nodes
                      .slice(4, 8)
                      .map((instaNode, index) => (
                        <div
                          key={index}
                          className={`col-xs-6 col-sm-3 no-padd insta-image-container ${
                            index > 1 ? "marg-xs-t10" : ""
                          }`}
                        >
                          <InstaMiniature instaNode={instaNode} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="site-info">
        <div className="site-branding">
          <h1 className="site-title">
            <Link to="/">{data.site.siteMetadata.title}</Link>
          </h1>
          <h6 className="site-slogan">
            <span className="fa fa-heart" /> Beth & Thomas
          </h6>
        </div>
      </div>
    </footer>
  )
}

export default Footer
