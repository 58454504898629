import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "current-menu-item" } : null
}

const Header = ({ siteTitle }) => {
  const [stickyNav, setStickyNav] = useState(false)

  useEffect(() => {
    const eventListener = event => {
      const topHead = document
        .querySelector(".site-header")
        .querySelector(".header-top")

      if (window.scrollY > topHead.offsetHeight) {
        setStickyNav(true)
      } else {
        setStickyNav(false)
      }
    }
    window.addEventListener("scroll", eventListener)
    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  })

  return (
    <header className="site-header">
      <div className="container-fluid header-top clearfix">
        <div className="row between-sm middle-sm">
          <div className="col-xs-12">
            <div className="site-branding">
              <h1 className="site-title">
                <Link to="/">lovethis.world</Link>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className={`wpc-navigation ${stickyNav ? "sticky" : ""}`}>
        <nav className="main-navigation">
          <ul className="main-menu">
            <li className="menu-item">
              <Link to="/" activeClassName="current-menu-item">
                HOME
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/globe" getProps={isPartiallyActive}>
                GLOBE
              </Link>
            </li>
            <li className="menu-item menu-item-has-children">
              <a href="#">
                CATEGORIES
                <span class="fa fa-angle-down" />
              </a>
              <ul className="sub-menu">
                <li className="menu-item">
                  <Link to="/sounds" getProps={isPartiallyActive}>
                    SOUNDS
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/sights" getProps={isPartiallyActive}>
                    SIGHTS
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/stories" getProps={isPartiallyActive}>
                    STORIES
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/picks" getProps={isPartiallyActive}>
                    PICKS
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <Link to="/about" getProps={isPartiallyActive}>
                ABOUT
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/share" getProps={isPartiallyActive}>
                SHARE
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
