import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share"
import { useStaticQuery, graphql } from "gatsby"

const ShareWidget = ({ slug }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
          }
        }
      }
    `
  )
  const shareUrl = data.site.siteMetadata.url + slug
  return (
    <ul className="c-share-list">
      <li>
        <FacebookShareButton url={shareUrl}>
          <a className="fa fa-facebook" />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={shareUrl}>
          <a className="fa fa-twitter" />
        </TwitterShareButton>
      </li>
      <li>
        <RedditShareButton url={shareUrl}>
          <a className="fa fa-reddit" />
        </RedditShareButton>
      </li>
      <li>
        <EmailShareButton url={shareUrl}>
          <a className="fa fa-envelope" />
        </EmailShareButton>
      </li>
    </ul>
  )
}

export default ShareWidget
