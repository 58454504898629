import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FacebookShareButton, FacebookShareCount } from "react-share"

const ShareCounter = ({ slug }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
          }
        }
      }
    `
  )
  const shareUrl = data.site.siteMetadata.url + slug
  return (
    <FacebookShareButton url={shareUrl} className="facebook-share-label">
      <FacebookShareCount url={shareUrl}>
        {shareCount => (
          <a>
            {shareCount} <span className="fa fa-facebook" /> shares
          </a>
        )}
      </FacebookShareCount>
    </FacebookShareButton>
  )
}

export default ShareCounter
